import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';


const TEAM = [
  {
    name: 'C-1',
    image: 'josh.png',
    role: 'Control Amp',
    pdf: 'stereo_Feb2022.pdf',
    ref: 'stereo 2022Feb(PDF)',
  },
  {
    name: 'M-2',
    image: 'lisa.png',
    role: 'POWER Amp',
    pdf: 'stereo_Feb2022.pdf',
    ref: 'stereo 2022Feb(PDF)',
  },
  {
    name: 'PH-3',
    image: 'ashlyn.png',
    role: 'Phono Amp',
    pdf: 'stereo_May2022.pdf',
    ref: 'stereo 2022May(PDF)',
  },
  {
    name: 'DAC Ref-2',
    image: 'martin.png',
    role: 'DAC',
    pdf: 'stereo_Jun2023.pdf',
    ref: 'stereo 2023Jun(PDF)',
  },
];


const Team = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "team" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        art_team: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "team_work" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="products" accent="secondary">
        <StyledContainer>
        <Container style={{ position: 'relative' }}>
          <h1>Products<span>PASIO（ハンドメイド・受注生産）</span></h1>
          <TeamGrid>
            {TEAM.map(({ name, image, role, pdf, ref }) => {
              const img = data.allFile.edges.find(
                ({ node }) => node.relativePath === image
              ).node;

              return (
                <div key={name}>
                  <Img fluid={img.childImageSharp.fluid} alt={name} />
                  <Title>{name}</Title>
                  <Subtitle>{role}</Subtitle>
                  <p class='ref'>ref. <a href={pdf}>{ref}</a></p>
                </div>
              );
            })}
          </TeamGrid>
        </Container>
        </StyledContainer>
      </Section>
    )}
  />
);


const StyledContainer = styled(Container)`
  margin-bottom: 10vh;
  padding-top: 10vh;
  text-align: center; 

  p.ref {
    font-size: small;

    a:link, a:visited {
      color: #0000ee;
    }
  }

  h1 {
    margin: 20px 0 40px;
    display: inline-block;


    > span {
      display: block;
      ${props => props.theme.font_size.regular};
      border-top: solid 1px ${props => props.theme.color.black.lighter};
    }
  }
  
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 0vh;
    padding-top: 0;

    h1 {
      margin: 0;
    }
  }
`;

const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 260px);
  grid-template-rows: min-content;
  grid-gap: 50px;
  justify-content: center;
  width: 100%;
  margin-top: 72px;

  @media (max-width: ${props => props.theme.screen.lg}) {
    justify-content: start;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    grid-gap: 24px;
  }
`;

const Title = styled.p`
  margin-top: 16px;
  color: ${props => props.theme.color.black.regular};
`;

const Subtitle = styled.p`
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.black.light};
`;

export default Team;
