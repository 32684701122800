import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => 
  <div style={{
    position: "absolute",
    transform: "translate(-50%, calc(-100% - 10px))",
  }}
>
  <div
    style={{
      padding: "5px",
      textAlign: "center",
      fontSize: "large",
      color: "white",
      backgroundColor: "black",
      wordBreak: "keep-all",
    }}
  >
    {text}
  </div>
  <div
    style={{
      position: "absolute",
      top: "100%",
      left: "50%",
      transform: "translate(-50%, 0)",
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderWidth: "10px 10px 0px 10px",
      borderColor: "black transparent transparent transparent",
    }}
  />
</div>



 
class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 35.86680926205419,
      lng: 139.3888948162175
    },
    zoom: 18
  };
  
  render() {
    return (
      //サイズの大きさを指定
      <div style={{ height: '500px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: `${'AIzaSyAKXRku6Z98fd_9Sq0s186etvuOXn4qObE'}` }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            //マーカーの緯度と経度
            lat={35.86664050186806}
            lng={139.3888739408596}
            text="MALC"
          />
        </GoogleMapReact>
      </div>
    );
  }
}
 


export default SimpleMap;