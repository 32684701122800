import React from 'react';
import styled from 'styled-components';

import { Container } from '@components/global';

const Header = () => (

      <HeaderWrapper>
        <Container>
          <Grid>
            <Text>
              <h1>MALC<span>Musashino Audio Labo Community</span></h1>
            </Text>
          </Grid>
        </Container>
      </HeaderWrapper>
);

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.primary};
`;

const Grid = styled.div`
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${props => props.theme.screen.md}) {
    height: 98vh;
  }
`;

const Text = styled.div`
  justify-self: center;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }

  > h1 {
    font-size: 104px;
    display: inline-block;
    
    > span {
      display: block;
      ${props => props.theme.font_size.small};
      padding: 0 5px;
      color: ${props => props.theme.color.white.dark};
      background: ${props => props.theme.color.black.regular};
      margin-top: 23px;
    }
  }
`;

export default Header;
