import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const Tech = () => (
  <StaticQuery
    query={graphql`
      query {
        tech1: file(
          sourceInstanceName: { eq: "tech" }
          name: { eq: "tell_story" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        tech2: file(
          sourceInstanceName: { eq: "tech" }
          name: { eq: "gnd" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="tech">
        <StyledContainer>
          <h1>MALC GND Technology<span>Unity Grounding&trade;</span></h1>
          <ul>
            <li>システムを構成する個々の回路GNDはそれぞれに独立して完結し、回路を収納する筐体GNDとは分離した構成をとる</li>
            <li>ch毎の分離で信号GNDの共通インピーダンスを排除する</li>
          </ul>
          <Art>
              <div><Img fluid={data.tech1.childImageSharp.fluid} /></div>
              <div>
                <h2>筐体GND,信号GNDの分離とチャンネルGNDの分離</h2>
                <ul>
                  <li>筐体GND,信号GNDの分離 : 筐体のGNDと信号GNDは別の扱いとする</li>
                  <li>チャンネルGNDの独立（分離） : チャンネル個々のGNDはそれぞれに独立した扱いとする</li>
                </ul>
              </div>
              <div><Img fluid={data.tech2.childImageSharp.fluid} /></div>
              <div>
                <h2>SPを含むコンポーネントの筐体電位の統一</h2>
                <ul>
                  <li>コンポーネント筐体電位の統一 : システムを構成するコンポーネントの筐体電位を統一する</li>
                </ul>
              </div>
          </Art>
        </StyledContainer>
      </Section>
    )}
  />
);


const StyledContainer = styled(Container)`
  margin-bottom: 10vh;
  padding-top: 10vh;
  text-align: center; 

  > h1 {
    margin: 20px 0 40px;
    display: inline-block;

    > span {
      display: block;
      ${props => props.theme.font_size.regular};
      border-top: solid 1px ${props => props.theme.color.black.lighter};
      margin-top: 10px;
    }
  }

  > ul {
    width: 85%;
    margin: 0 auto 10vh auto;
    text-align: left;
    list-style: none;
  }
  li {
    margin-bottom: 15px;
    padding: 0 5px 5px 5px;
    border-bottom: 1px solid ${props => props.theme.color.black.lighter};
  }
  li::before {
    content:  "∴";
    padding-right: 5px;
    font-color: ${props => props.theme.color.black.dark};
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 0vh;
    padding-top: 0;

    > ul {
      margin: 0 auto 5vh;
      padding: 0;
      width: 100%;
    }
  }
`;

const Art = styled.div`
  margin-top: 40px;
  display: grid;
  gap: 10px;
  grid-template-columns: 350px 1fr;
  margin: 0 auto;
  width: 85%;
    
  h2 {
    ${props => props.theme.font_size.regular};
    font-family: ${props => props.theme.font.secondary};
  }

  ul {
    list-style: none;
    text-align: left;

    li {
      border: none;
    }
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 95%;
    display: flex;
    flex-flow: column;

    h2 {
      ${props => props.theme.font_size.small};
      font-weight: bold;
    }

    ul {
      margin: 3vh auto 5vh;
      padding: 0;
    }
  }
`;

export default Tech;
