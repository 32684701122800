import React from 'react';
import styled from 'styled-components';

import { Section, Container } from '@components/global';

const AboutUs = () => (
  <Section id="aboutus" accent="secondary">
    <Container>
      <Aboutus>
        <h1>About Us</h1>
        <Theme>～音楽、音、夢に集う～</Theme>
        <p>音楽文化の創造を通して社会に奉仕し、信頼と尊敬、利益を得て集いを存続しつづける</p>
      </Aboutus>
      <Grid>
          <h2><span>MALC</span>オーディオの立場<span><span>Desain Concept</span></span></h2>
          <blockquote cite="http://www.domonken-kinenkan.jp/domonken/">
            <p>生演奏がそこにあるから、生演奏を聴いているから、オーディオはいらないと云われるオーディオでは、実情けない。<br />
            生演奏がそこにあっても、生演奏を何度聴いても、生演奏以上に聴かせてくれるオーディオが、本当のオーディオというものである。</p>
            引用元：<cite><a href="http://www.domonken-kinenkan.jp/domonken/">土門拳とその作品 | 山形県酒田市 土門拳記念館</a></cite>
          </blockquote>
      </Grid>
      <Grid>
          <h2>作り手の思い<span><span>Desain Concept</span></span></h2>
          <div>
            <dl>
              <dt>製作品</dt><dd>音楽再生のメカニックの一つであると共に、アートであること</dd>
              <dt>信号回路</dt><dd>動作の基本に忠実であること</dd>
              <dt>電源回路</dt><dd>電源供給の基本に忠実であること</dd>
              <dt>システム構成</dt><dd>ステレオ再生の基本に忠実であること</dd>
              <dt>デバイス</dt><dd>理想に忠実であること</dd>
              <dt>製作</dt><dd>技量に忠実で、科学的であること</dd>
            </dl>
          </div>
      </Grid>
    </Container>
  </Section>
);

const Aboutus = styled.div`
  margin-bottom: 10vh;
  padding-top: 10vh;
  border-top: solid 2px ${props => props.theme.color.white.dark};
  text-align: center; 

  > h1 {
    margin: 20px 0;
  }

  > p {
    ${props => props.theme.font_size.small};
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 10vh;
    padding-top: 0;
  }
`;

const Theme = styled.div`
  margin-bottom: 10px;
  font-family: ${props => props.theme.font.primary};
  ${props => props.theme.font_size.large};
`;

const Grid = styled.div`
  text-align: center;
  margin-bottom: 10vh;

  background: ${props => props.theme.color.white.dark};

  h2 {
    display: inline-block;
    font-family: ${props => props.theme.font.primary};

    span {
      font-family: ${props => props.theme.font.secondary};

      > span {
        display: block;
        font-family: ${props => props.theme.font.secondary};
        ${props => props.theme.font_size.small};
        border-top: solid 1px ${props => props.theme.color.black.lighter};
      }
    }
  }

  > p {
    ${props => props.theme.font_size.small};
  }

  > blockquote {
    padding: 20px;
    border-left: 5px solid ${props => props.theme.color.black.lighter};
    width: 85%;
    margin: 0 auto;
    text-align: left;

    > p {
      ${props => props.theme.font_size.small};
      text-align: left;
      margin-bottom: 20px;
    }
    
    > cite {
      text-align: center;
    }

    a {
      text-decoration: none;
    }

    a:visited {
      color: ${props => props.theme.color.black.light};
    }
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    > blockquote {
      width: 95%;
    }
  }

  > div {
    width: 85%;
    margin: 0 auto;
    text-align: left;

    > dl {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
    
      dt {
        flex-basis: 15%;
        padding: 15px;
        border-bottom: 1px solid ${props => props.theme.color.black.lighter};
        }

        dd {
        flex-basis: 84%;
        padding: 15px;
        margin: 0 0 0 5px;
        border-bottom: 1px solid ${props => props.theme.color.black.light};
        }
        
        dt::after{
          content: "："
        }
    }
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    > div {
      width: 95%;
  
      > dl {
        flex-flow: column;
  
        dt {
          border: none;
          font-weight: bold;
          padding: 10px 0 0;
        }
      }
    }
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }
  }
`;

export default AboutUs;
