import React from 'react';
import styled from 'styled-components';
import { Section, Container } from '@components/global';

import Ceo from "../../images/more/ksasaki.png";
import Dac from "../../images/more/dac.jpg";
import Hpa from "../../images/more/headphoneamp.jpg";
import Phono from "../../images/more/phonoamp.jpg";
import Power from "../../images/more/poweramp.jpg";
import Pre from "../../images/more/preamp.jpg";
import Premain from "../../images/more/premainamp.jpg";
import Sp from "../../images/more/sp.jpg";

import FaqItem from '@common/FaqItem';

const MORE = [
  {
    title: 'CEO / CTO',
    content: () => (
      <>
        <Profgrid>
          <img src={ Ceo } alt="佐々木 勝弘 | SASAKI Katsuhiro" />
        </Profgrid>
        <h2>佐々木 勝弘<span>SASAKI Katsuhiro</span></h2>
          <p>1953年2月2日生まれ。秋田県出身。<br />
          1971年工業高校卒業後、パイオニアに入社。オーディオアンプの開発・設計業務に従事。<br />
          ExculusiveシリーズやZ1シリーズなどのフラグシップモデルの設計を担当。<br />
          東北パイオニアでは、音質変化の要素解析、車室音場解析等の研究に従事。
          </p>
        <h3>好きな言葉</h3>
          <blockquote>
            <p>青春とは人生の或る期間を言うのではなく心の様相を言うのだ。<br />
            優れた創造力、逞しき意志、炎ゆる情熱、怯懦を却ける勇猛心、安易を振り捨てる冒険心、こう言う様相を青春と言うのだ。<br />
            年を重ねただけで人は老いない。理想を失う時に初めて老いがくる。</p>
            引用元：<cite>青春 サミュエル・ウルマン</cite>
          </blockquote>
        <h3>好きな楽器</h3>
          <p style={{textAlign: 'center'}}>ピアノ</p>
        <h3>好きな曲想</h3>
          <p style={{textAlign: 'center'}}>ピアノ三重奏（凡例…ドボルザーク”Dumky”）</p>
      </>
    ),
  },
  {
    title: 'Press Release',
    content: () => (
      <>
        <ul>
          <li><a href="20240325.pdf">Control amp Ref-1 (2024年3月25日)</a> [PDF]</li>
          <li><a href="20231017.pdf">DA Converter Ref-2 (2023年10月17日)</a> [PDF]</li>
        </ul>
      </>
    ),
  },
  {
    title: 'Trial Products',
    content: () => (
      <>
        <p>MALCアンプの基本パフォーマンス確認を目的に製作した試作機。<br />詳細はお問合せ下さい。</p>
        <TrialproductsGrid>
          <div>
            <img src={ Premain } alt="プリメインアンプ" />
            <figcaption>Premain Amp</figcaption>
          </div>
          <div>
            <img src={ Power } alt="パワーアンプ" />
            <figcaption>Power Amp</figcaption>
          </div>
          <div>
            <img src={ Pre } alt="プリアンプ" />
            <figcaption>Pre Amp</figcaption>
          </div>
          <div>
            <img src={ Phono } alt="フォノアンプ" />
            <figcaption>Phono Amp<br />（MC対応）</figcaption>
          </div>
          <div>
            <img src={ Dac } alt="DAC" />
            <figcaption>DAC</figcaption>
          </div>
          <div>
            <img src={ Sp } alt="16cmフルレンジスピーカー" />
            <figcaption>FullRange SP<br />（16cm）</figcaption>
          </div>
          <div>
            <img src={ Hpa } alt="ヘッドホンアンプ" />
            <figcaption>Headphone Amp<br />（携帯型/電池駆動）</figcaption>
          </div>
        </TrialproductsGrid>
      </>
    ),
  },
  {
    title: 'Consultation services',
    content: () => (
      <>
      　<p>メーカーを問わずオーディオ機器の修理、メンテナンスを行っております。<br />詳細はお問合せ下さい。</p>
      </>
    ),
  },
];

const More = () => (
  <Section id="more">
    <StyledContainer>
      <h1 style={{ marginBottom: 40 }}>Learn More</h1>
      <div>
        {MORE.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </StyledContainer>
  </Section>
);


const TrialproductsGrid = styled.div`
  display: grid;
  grid-template-rows: 215px;
  grid-template-columns: repeat(auto-fill, 160px);
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  justify-content: space-evenly; 

  > div {
    display: inline;

    img {
      width: 160px;
      height: auto;
    }

    figcaption {
      ${props => props.theme.font_size.small};
      font-family: ${props => props.theme.font.secondary};
    }
  }
`;

const Profgrid = styled.div`
  margin: 0 auto;
`;

const StyledContainer = styled(Container)`
  margin-bottom: 10vh;
  padding-opt: 10vh;
  text-align: center; 

  h1 {
    margin: 20px 0 40px;
    display: inline-block;

    > span {
      display: block;
      ${props => props.theme.font_size.regular};
      border-top: solid 1px ${props => props.theme.color.black.lighter};
      margin-top: 10px;
    }
  }

  h2 {
    ${props => props.theme.font_size.regular};
    font-family: ${props => props.theme.font.primary};
    margin-bottom: 20px;
    display: inline-block;

    > span {
      display: block;
      ${props => props.theme.font_size.small};
      font-family: ${props => props.theme.font.secondary};
      border-top: solid 1px ${props => props.theme.color.black.lighter};
      margin-top: 10px;
    }
  }

  p {
    text-align: left;
    ${props => props.theme.font_size.small};
    margin-bottom: 40px;
  }

  h3 {
    font-family: ${props => props.theme.font.primary};
    ${props => props.theme.font_size.regular};
    margin-bottom: 20px;
    display: inline-block;
    border-bottom: solid 1px ${props => props.theme.color.black.lighter};
  }

  blockquote {
    background: ${props => props.theme.color.white.dark};
    padding: 10px;
    margin: 0 auto 40px auto;
    text-align: left;
  
    > p {
      ${props => props.theme.font_size.small};
      text-align: left;
      margin-bottom: 20px;
    }
    
    > cite {
      text-align: center;
    }
  }

  ul {
    text-align: left;

    li  {
      margin-bottom: 5px;

      a:link, a:visited {
        color: #0000ee;
      }
    }
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 0vh;
    padding-top: 0;

    h1 {
      margin: 0;
    }
  }
`;

export default More;
