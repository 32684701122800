import React from 'react';
import styled from 'styled-components';

import { Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';



const Footer = () => (



        <FooterWrapper>
          <StyledContainer>
            <Copyright>
              <h2>MALC LLC <span>Musashino Audio Labo Community</span></h2>
              <ul>
                <li>〒350-1328 埼玉県狭山市広瀬台2-16-15 <ExternalLink href="https://sic21.jp/">SIC21</ExternalLink> 304</li>
                <li>mail : <a href="mailto:alabo.malc@gmail.com">alabo.malc@gmail.com</a></li>
              </ul>
            </Copyright>

          </StyledContainer>
        </FooterWrapper>

);



const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.color.white.regular};
  padding: 32px 0;
`;

const Copyright = styled.div`
  font-family: ${props => props.theme.font.secondary};
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.black.regular};

  a {
    text-decoration: none;
    color: inherit;
  }

  ul {
    list-style: none;
  }
  
  
h2 {
  border-bottom: solid 1px ${props => props.theme.color.black.regular};
}
  span {
    ${props => props.theme.font_size.small};
  }

`;



const StyledContainer = styled(Container)`

`;

export default Footer;
