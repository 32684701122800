import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Header from '@sections/Header';
import AboutUs from '@sections/AboutUs';
import Tech from '@sections/Tech';
import Products from '@sections/Products';
import More from '@sections/More';
import Googlemap from '@sections/Googlemap';
import Footer from '@sections/Footer';

const IndexPage = () => (
  <Layout>
    <Navbar />
    <Header />
    <AboutUs />
    <Tech />
    <Products />
    <More />
    <Googlemap />
    <Footer />
  </Layout>
);

export default IndexPage;
